import axios from 'axios'

export default ({
  namespaced: true,
  state: {
    suratSehat    : null,
    loading       : null
  },
  getters: {
    getSuratSehat(state){
      return state.suratSehat;
    }
  },
  mutations: {
    setSuratSehat(state, value) { state.suratSehat = value },
    setLoading(state, value) {
        state.loading = value
    }
  },
  actions: {
    async fetchSuratSehat({commit}, payload){
      commit("setLoading", true)
      await axios.get(`api/surat/getSuratSehatPaginate/${payload.cabang_id}/${payload.page}`)
      .then((response) => {
        commit("setSuratSehat", response.data)
      })
      .catch(() => {
        commit("setSuratSehat", null)
      })
      .finally(() => {
        commit('setLoading', false)
      })
    },
    createSuratSehat({commit}, payload){
        commit('setLoading', true)
        axios.get('api/surat/createSuratSehat', payload)
        .then((response) => {
            if(response.status == 200) return true
        })
        .catch(() => {
            return false
        })
        .finally(() => {
            commit('setLoading', true)
        })
    }
  }
})