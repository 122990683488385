import axios from 'axios'

export default ({
  namespaced: true,
  state: {
    poli    : null,
    loading : null
  },
  getters: {
    getPoli(state){
      return state.poli;
    }
  },
  mutations: {
    setPoli(state, value) { state.poli = value },
    setLoading(state, value) {
        state.loading = value
    }
  },
  actions: {
    async fetchPoli({commit}, payload){
      commit("setLoading", true)
      await axios.get(`poli/getByCabangId/${payload.tbCabangId}`)
      .then((response) => {
        commit("setPoli", response.data.data)
      })
      .catch(() => {
        commit("setPoli", null)
      })
      .finally(() => {
        commit('setLoading', false)
      })
    }
  }
})