<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': akuntansiActive}" >
        <a href="#" class="nav-link" @click="akuntansiActive = !akuntansiActive">
          <i class="nav-icon fas fa-calculator"></i>
          <p>
            Akuntansi
            <!-- <i class="right fas fa-angle-left"></i> -->
          </p>
        </a>
        <ul class="nav nav-treeview">
          <li class="nav-item pl-2" v-if="props.akses.includes('data_akun_keuangan') || props.akses.includes('semua')">
            <router-link to="/data-akun-keuangan" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              <p class="text-sm">Data Akun Keuangan</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('data_jurnal_keuangan') || props.akses.includes('semua')">
            <router-link to="/data-jurnal-keuangan" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              <p class="text-sm">Data Jurnal Keuangan</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('data_jurnal_singkat') || props.akses.includes('semua')">
            <router-link to="/data-jurnal-singkat" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              <p class="text-sm">Data Jurnal Singkat</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('data_budgeting_akun_keuangan') || props.akses.includes('semua')">
            <router-link to="/data-budgeting-keuangan" class="nav-link">
              <i class="far fa-circle nav-icon"></i>
              <p class="text-sm">Data Budgeting Akun Keuangan</p>
            </router-link>
          </li>
        </ul>
      </li>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const akuntansiActive = ref(false)

const props = defineProps({
  akses: Array
})

</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>