import axios from 'axios'

export default ({
    namespaced: true,
    state: {
        loading   : false,
        config    : null
    },
    getters: {
        getLoading(state){
            return state.loading;
        },
        getConfig(state){
            return state.config;
        },
    },
    actions: {
        async generateToken({ commit, state }, payload) {
            commit('setLoading', true)
            commit('setConfig', { client_id : payload.client_key, client_secret : payload.secret_key })
            return await axios.post('satu-sehat/createtoken', state.config)
            .then((response) => {
                // add token to local storage
                localStorage.setItem('tokenSatuSehat', response.data.access_token)
                
                // Hapus token setelah 1 jam
                // setTimeout(() => {
                //     localStorage.removeItem('tokenSatuSehat'); 
                    
                //     // Panggil generateToken lagi setelah 1 jam
                //     this.$store.dispatch('generateToken', state.config);
                // }, 5000);
            })
            .finally(() => {
                commit('setLoading', false)
            })
        },
        async createOrganization({ commit }, payload) {
            commit('setLoading', true)
            return axios.post('satu-sehat/organization', payload)
            .finally(() => {
                commit('setLoading', false)
            })
        },
        async createLocation({ commit }, payload) {
            commit('setLoading', true)
            return axios.post('satu-sehat/create-location', payload)
            .finally(() => {
                commit('setLoading', false)
            })
        }
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value
        },
        setConfig(state, value) {
            state.config = value
        },
    }
})