<template>
	<div class="hold-transition login-page"
		style="background: rgb(41,128,185);
background: linear-gradient(148deg, rgba(41,128,185,1) 0%, rgb(255, 255, 255) 25%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 75%, rgba(255,106,136,1) 100%);">
		<LoadingScreen v-if="loading_screen" />
		<div class="w-full d-flex flex-column mb-2" style="height: auto;">
			<img :src="image ? image : defaultImage" alt=""
				style="width: 255px;height: 250px;display: block;margin-left: auto;margin-right: auto;">
		</div>
		<div class="text-center text-sm font-weight-bold">{{ cabang.nama }}</div>
		<div class="text-center text-sm font-weight-bold">{{ cabang.alamat }}</div>
		<div class="text-center text-sm font-weight-bold">No Izin Klinik : {{ cabang.sio }}</div>
		<div class="text-center text-sm font-weight-bold">Phone : {{ cabang.telepon }}</div>
		<br>
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
					<div class="card-header ">
						<div class="text-center">
							<h1>Selamat Datang Di</h1>
							<h1 class="text-bold">ANJUNGAN PENDAFTARAN MANDIRI</h1>
							<h5>Silakan menekan tombol untuk mengambil Antrian</h5>
						</div>
					</div>
					<div class="card-body" style="display: block;">
						<div class="row justift-content-center">
							<div class="col">
								<button style="width: 100%;" type="submit" class="btn btn-success py-3" data-toggle="modal"
									data-target="#exampleModal" @click="jenisPasien = 'Umum'">ANTRIAN PASIEN BPJS
								</button>
							</div>
						</div>
					</div>
					<!-- /.card-body -->
					<div class="card-footer" style="display: block;">

					</div>
					<!-- /.card-footer-->
				</div>
			</div>
		</div>

		<!-- Modal BPJS-->
		<div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-titl text-center text-bold" id="exampleModalLabel">Silahkan Masukan
							Nomor BPJS / NIK Anda</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form @submit.prevent="prosesKunjungan">
							<div class="row">
								<div class="col-lg-2"></div>
								<div class="col-lg-8">
									<div class="form-group">
										<label for="nomor">Nomor Antrian</label>
										<input type="text" class="form-control" id="nomor" disabled="" name="nomor"
											placeholder="" v-model="kunjunganBaru.nomor">
									</div>
									<div v-if="jenisPasien == 'Umum'" class="w-100 mb-3">
										<label for="pasien">Pasien <span class="text-danger">*</span></label>
										<el-select id="pasien" v-model="kunjunganBaru.tbPasienId" filterable remote
											reserve-keyword placeholder="Masukkan Nama Pasien" class="w-100"
											:remote-method="getPasienRemote" :loading="loading_pasien">
											<el-option v-for="(row, index) in dataPasien" :key="index"
												:label="customSelectPasien(row)" :value="row.id"
												@click="getIhsPatient(row)" />
										</el-select>
										<div v-if="status.patient !== null && kunjunganBaru.tbPasienId" class="mt-2">
											<el-tag v-if="status.patient" type="success">NIK Terdaftar di
												Satu Sehat</el-tag>
											<el-tag v-else type="danger">NIK Tidak Diketahui di Satu
												Sehat</el-tag>
										</div>
									</div>
									<div class="form-group">
										<label class="typo__label">Poli <span class="text-danger">*</span></label><br>
										<el-select v-model="kunjunganBaru.tbPoliId" placeholder="Pilih Poli" filterable
											class="w-100" :disabled="cabangs == '15'" @change="getKunjungan">
											<el-option v-for="(row, index) in dataPoli" :key="index" :value="row.id"
												:label="row.nama"></el-option>
										</el-select>
										<p class="text-sm text-danger" v-if="cek_data_poli">Data Poli Masih
											Kosong</p>
									</div>
									<div class="form-group">
										<label class="typo__label">Layanan <span class="text-danger">*</span></label><br>
										<el-select v-model="kunjunganBaru.layananId" placeholder="Pilih Layanan" filterable
											class="w-100" disabled @change="getKunjungan">
											<el-option v-for="(row, index) in dataLayanan" :key="index" :value="row.id"
												:label="row.nama"></el-option>
										</el-select>
										<p class="text-sm text-danger" v-if="cek_data_poli">Data Poli Masih
											Kosong</p>
									</div>
									<button type="submit" class="btn btn-primary d-flex align-items-center mt-4"
										data-dismiss="#exampleModalUmum">
										<div>Submit</div>
										<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</button>
								</div>
								<div class="col-lg-2"></div>
							</div>
						</form>
					</div>
					<div class="modal-footer"></div>
				</div>
			</div>
		</div>

	</div>
</template>

<script setup>
// import ContentHeader from '@/components/ContentHeader'
import LoadingScreen from '@/components/LoadingScreen'

import { ref, computed, reactive, onMounted } from 'vue'
import axios from 'axios'
import store from '@/store'
import Swal from 'sweetalert2'
import umur from '@/umur.js'
import dayjs from 'dayjs'
import router from '@/router'
import { laravel } from '@/main'

const user = computed(() => store.getters['auth/user'])
const cabang = computed(() => store.getters['auth/cabangs'])
const logo = computed(() => store.getters['auth/logo'])
const image = ref()
image.value = logo.value.config.baseURL + '/' + logo.value.data.data.logo

const cabangs = user.value.data.tbCabangId
const jenisPasien = ref('')


let now = new Date()
now.setDate(now.getDate() + 7)
const kunjungan_berikutnya = ref(now.toDateString())
kunjungan_berikutnya.value = dayjs(kunjungan_berikutnya.value).format('DD/MM/YYYY')

// PASIEN
const dataPasien = ref([])
// const pasienbarulahir = ref([])
const pasientidakdikenal = ref([])
const loading_pasien = ref(false)
const cek_data_pasien = ref(false)

const getPasienRemote = async (val) => {
	loading_pasien.value = true
	let response = await axios.get(`pasien/get/${user.value.data.tbCabangId}/${val}?page=1`)

	if (response.data == 'kosong') {
		loading_pasien.value = false
		cek_data_pasien.value = true
		dataPasien.value = []
	} else {
		loading_pasien.value = false
		cek_data_pasien.value = false
		dataPasien.value = response.data.data
	}
}

const getPasienTidakDikenalRemote = async () => {
	loading_pasien.value = true
	let response = await axios.get(`pasien/getunknown/${user.value.data.tbCabangId}?page=1`)

	if (response.data == 'kosong') {
		loading_pasien.value = false
		cek_data_pasien.value = true
		pasientidakdikenal.value = []
	} else {
		loading_pasien.value = false
		cek_data_pasien.value = false
		pasientidakdikenal.value = response.data.data
	}
}

// const getPasienBaruLahirRemote = async (val) => {
// 	loading_pasien.value = true
// 	let response = await axios.get(`pasien/getnewborn/${user.value.data.tbCabangId}/${val}?page=1`)

// 	if (response.data == 'kosong') {
// 		loading_pasien.value = false
// 		cek_data_pasien.value = true
// 		pasienbarulahir.value = []
// 	} else {
// 		loading_pasien.value = false
// 		cek_data_pasien.value = false
// 		pasienbarulahir.value = response.data.data
// 	}
// }


const customSelectPasien = ({ nomor_rekam_medis, nama, alamat_lengkap, tanggal_lahir }) => {
	tanggal_lahir = tanggal_lahir ? `${umur(tanggal_lahir)} Tahun - ` : ''
	return `${nomor_rekam_medis} - ${nama} - ${tanggal_lahir} ${alamat_lengkap}`
}

// const customSelectPasienTidakDikenal = ({ perkiraan_umur, lokasi_ditemukan, tanggal_ditemukan }) => {
// 	return `${perkiraan_umur} - ${lokasi_ditemukan} - ${tanggal_ditemukan}`
// }

// const customSelectPasienBaruLahir = ({ nomor_rekam_medis, nama_bayi, jenis_kelamin, tgl_lahir_bayi }) => {
// 	tgl_lahir_bayi = tgl_lahir_bayi ? `${umur(tgl_lahir_bayi)} Tahun - ` : ''
// 	return `${nomor_rekam_medis} - ${nama_bayi} - ${tgl_lahir_bayi} ${jenis_kelamin}`
// }
// END PASIEN

// DOKTER
const dataDokter = ref([])
const loading_dokter = ref(true)
const cek_data_dokter = ref(false)

const getDokter = async () => {
	let response = await axios.get(`karyawan/getByJabatan/${user.value.data.tbCabangId}/1`)
	if (response.data.data == 0) {
		loading_dokter.value = false
		cek_data_dokter.value = true
		dataDokter.value = []
	} else {
		loading_dokter.value = false
		cek_data_dokter.value = false
		dataDokter.value = response.data.data
	}
}
// END DOKTER


// POLI
const dataPoli = ref([])
const loading_poli = ref(true)
const cek_data_poli = ref(false)

const getPoli = async () => {
	await axios.get(`poli/get`)
		.then((response) => {
			if (response.data == 'kosong') {
				loading_poli.value = false
				cek_data_poli.value = true
				dataPoli.value = []
			} else {
				loading_poli.value = false
				cek_data_poli.value = false
				dataPoli.value = response.data.data
			}

			getLayanan()

		})

}

// END POLI

const cekCabangLogin = (poli, layanan) => {
	if (cabangs == '15') {
		poli.map((val) => {
			if (val.nama.toLowerCase() == 'kecantikan') {
				kunjunganBaru.tbPoliId = val.id
			}
		})

		layanan.map((val) => {
			if (val.nama.toLowerCase() == 'umum/mandiri') {
				kunjunganBaru.layananId = val.id
			}
		})

	}
}

// LAYANAN
const dataLayanan = ref([])
const loading_layanan = ref(true)
const cek_data_layanan = ref(false)

const getLayanan = async () => {
	await axios.get(`layanan/get`)
		.then((response) => {
			if (response.data == 'kosong') {
				loading_layanan.value = false
				cek_data_layanan.value = true
				dataLayanan.value = []
			} else {
				loading_layanan.value = false
				cek_data_layanan.value = false
				dataLayanan.value = response.data.data
			}

			cekCabangLogin(dataPoli.value, dataLayanan.value)
		})

}
// END LAYANAN

// RUANG PERIKSA
const dataRuang = ref([])
const loading_ruang = ref(true)
const cek_data_ruang = ref(false)

const getRuang = async () => {
	await laravel.get(`api/ruang-periksa?cabang_id=${user.value.data.tbCabangId}`)
		.then((response) => {
			if (response.data == 'kosong') {
				loading_ruang.value = false
				cek_data_ruang.value = true
				dataRuang.value = []
			} else {
				loading_ruang.value = false
				cek_data_ruang.value = false
				dataRuang.value = response.data
			}
		})

}
// END PERIKSA

// KUNJUNGAN
const { getKunjungan, prosesKunjungan, kunjunganBaru, loading, loading_screen } = useKunjungan(user, cekCabangLogin, dataPoli)
// END KUNJUNGAN

// Satu Sehat
const status = ref({
	patient: null,
	practitioner: null,
	location: null,
})
const ihs = ref({
	patient: null,
	practitioner: null,
	location: null,
})
const selected_data = ref({
	patient: null,
	practitioner: null,
	location: null,
})
const getIhsPatient = async (_patient) => {
	selected_data.value.patient = _patient
	if (_patient.nomor_nik) {
		await axios.get(`satu-sehat/get-patient?nik=${_patient.nomor_nik}`)
			.then((response) => {
				if (response.data.total > 0) {
					ihs.value.patient = response.data.entry[0].resource.id
					status.value.patient = true
				} else {
					status.value.patient = false
				}
			})
	} else {
		status.value.patient = false
	}
}
// const getIhsPractitioner = async (_practitioner) => {
// 	selected_data.value.practitioner = _practitioner
// 	if (_practitioner.nik) {
// 		await axios.get(`satu-sehat/get-practitioner?nik=${_practitioner.nik}`)
// 			.then((response) => {
// 				if (response.data.total > 0) {
// 					ihs.value.practitioner = response.data.entry[0].resource.id
// 					status.value.practitioner = true
// 				} else {
// 					status.value.practitioner = false
// 				}
// 			})
// 	} else {
// 		status.value.patient = false
// 	}
// }
// const checkIhsRuangPeriksa = (_location) => {
// 	kunjunganBaru.lokasi_ihs = _location.ihs
// 	selected_data.value.location = _location
// 	if (_location.ihs) {
// 		ihs.value.location = _location.ihs
// 		status.value.location = true
// 	} else {
// 		status.value.location = false
// 	}
// }
// End Satu Sehat

onMounted(() => {
	getDokter()
	getKunjungan()
	getPoli()
	getPasienTidakDikenalRemote()
	getRuang()
})

function useKunjungan(user, cekCabangLogin, dataPoli) {
	const kunjungan = ref()
	const kunjunganBaru = reactive({
		'tbCabangId': user.value.data.tbCabangId,
		'nomor': null,
		'tbPasienId': null,
		'pasienTidakDikenalId': null,
		'pasienBaruLahirId': null,
		'tbKaryawanId': null,
		'tbPoliId': 1,
		'layananId': 2,
		'status': 'Dalam Antrian',
		'jenis': 'resepsionis',
		'kunjungan_berikutnya': kunjungan_berikutnya,
		'jenis_pasien': 'Umum',
		'ruang_periksa': null,
		'lokasi_ihs': null
	})

	const cekDataKunjungan = ref()
	const loading = ref(false)
	const loading_screen = ref(true)

	const tahun = ref()
	const bulan = ref()
	const hari = ref()
	const date = new Date()

	const kodelayanan = ref('')
	const kodepoli = ref('')
	const getKunjungan = async () => {

		if (kunjunganBaru.tbPoliId == 1) {
			kodepoli.value = 'A'
		} else if (kunjunganBaru.tbPoliId == 2) {
			kodepoli.value = 'B'
		} else if (kunjunganBaru.tbPoliId == 3) {
			kodepoli.value = 'C'
		} else if (kunjunganBaru.tbPoliId == 4) {
			kodepoli.value = 'D'
		} else if (kunjunganBaru.tbPoliId == 5) {
			kodepoli.value = 'E'
		} else if (kunjunganBaru.tbPoliId == 6) {
			kodepoli.value = 'F'
		} else if (kunjunganBaru.tbPoliId == 7) {
			kodepoli.value = 'G'
		} else if (kunjunganBaru.tbPoliId == 8) {
			kodepoli.value = 'H'
		}

		if (kunjunganBaru.layananId == 1) {
			kodelayanan.value = 'A'
		} else if (kunjunganBaru.layananId == 2) {
			kodelayanan.value = 'B'
		} else if (kunjunganBaru.layananId == 3) {
			kodelayanan.value = 'C'
		} else if (kunjunganBaru.layananId == 4) {
			kodelayanan.value = 'D'
		} else if (kunjunganBaru.layananId == 5) {
			kodelayanan.value = 'E'
		} else if (kunjunganBaru.layananId == 6) {
			kodelayanan.value = 'F'
		} else if (kunjunganBaru.layananId == 7) {
			kodelayanan.value = 'G'
		}


		tahun.value = date.getFullYear()
		tahun.value = tahun.value.toString().slice(2, 4)
		bulan.value = date.getMonth() + 1
		bulan.value = (bulan.value > 9) ? bulan.value : '0' + bulan.value
		hari.value = date.getDate()
		hari.value = (hari.value > 9) ? hari.value : '0' + hari.value

		let response = await axios.get('anjungan/get/' + user.value.data.tbCabangId)
		let responses = await axios.get(`anjungan/getLayanan/${user.value.data.tbCabangId}/${kunjunganBaru.tbPoliId}/${kunjunganBaru.layananId}`)



		if (response.data.data == null) {
			cekDataKunjungan.value = 'Belum Ada Kunjungan'
			kunjungan.value = null
			kunjunganBaru.nomor = `${kodepoli.value}${kodelayanan.value}${tahun.value}${bulan.value}${hari.value}00001`
			loading_screen.value = false
		} else {
			cekDataKunjungan.value = ''
			kunjungan.value = response.data.data
			let poliId = responses.data.data
			// let poli = response.data.data[0].tbPoliId
			// let layanan = response.data.data[0].layananId
			// let no_urut = response.data.data[0].nomor
			// no_urut = no_urut.slice(12, 13)
			// no_urut = parseInt(no_urut) + 1
			// no_urut = (no_urut > 9) ? (no_urut > 99) ? no_urut : '0' + no_urut : '0000' + no_urut
			// if (poli != kunjunganBaru.tbPoliId || layanan != kunjunganBaru.layananId) {
			// 	kunjunganBaru.nomor = `${kodepoli.value}${kodelayanan.value}${tahun.value}${bulan.value}${hari.value}00001`
			// } else 
			if (poliId != null) {
				let kodenomor = responses.data.data.nomor
				kodenomor = kodenomor.slice(0, 2)
				let respons = await axios.get(`anjungan/getnomor/${user.value.data.tbCabangId}/${kunjunganBaru.tbPoliId}/${kunjunganBaru.layananId}/${kodenomor}`)
				if (respons.data.data != null) {
					let nomor = respons.data.data.nomor
					nomor = nomor.slice(9, 13)
					nomor = parseInt(nomor) + 1
					nomor = (nomor > 9) ? (nomor > 99) ? nomor : '000' + nomor : '0000' + nomor
					kunjunganBaru.nomor = `${kodepoli.value}${kodelayanan.value}${tahun.value}${bulan.value}${hari.value}${nomor}`
				} else {
					kunjunganBaru.nomor = `${kodepoli.value}${kodelayanan.value}${tahun.value}${bulan.value}${hari.value}00001`
				}
			} else {
				kunjunganBaru.nomor = `${kodepoli.value}${kodelayanan.value}${tahun.value}${bulan.value}${hari.value}00001`
			}
			loading_screen.value = false
		}

	}

	// Proses Kunjungan
	const resetKunjunganBaru = () => {
		kunjunganBaru.nomor = null
		kunjunganBaru.tbPasienId = null
		kunjunganBaru.tbKaryawanId = null
		kunjunganBaru.tbPoliId = 1
		kunjunganBaru.layananId = 2
		kunjunganBaru.ruang_periksa = null
	}

	const prosesKunjungan = () => {
		
			if (loading.value == false) {
				if (kunjunganBaru.tbPasienId && kunjunganBaru.tbPoliId && kunjunganBaru.layananId) {
					loading.value = true
					axios.post('anjungan/create', kunjunganBaru)
						.then((response) => {
							if (response.data == 'sudah ada') {
								Swal.fire({
									title: 'Gagal!',
									text: 'Pasien Dalam Antrian Poli',
									icon: 'error',
									showConfirmButton: false,
									timer: 1500
								})
								loading.value = false
							} else {
								Swal.fire({
									title: 'Berhasil!',
									text: 'Berhasil Menambahkan Kunjungan',
									icon: 'success',
									showConfirmButton: false,
									timer: 1500
								})
								loading.value = false
								resetKunjunganBaru()
								getKunjungan()
								cekCabangLogin(dataPoli.value, dataLayanan.value)
								localStorage.removeItem('console')
								router.push(`/antrian-pasien/${response.data.data.id}`)
								setTimeout(function(){
									location.reload();
								}, 2000)
							}
						})
						.catch((error) => {
							Swal.fire({
								title: 'Gagal!',
								text: 'Gagal menambahkan data',
								icon: 'error',
								showConfirmButton: false,
								timer: 1500
							})
							loading.value = false
							console.log(error)
						})
				} else {
					Swal.fire({
						title: 'Gagal!',
						text: 'Mohon Maaf Silahkan Ulangi Isi Semua Form',
						icon: 'error',
						showConfirmButton: false,
						timer: 1500
					})
				}
			}
		
		
		
	}
	// End Proses Kunjungan

	return { kunjungan, getKunjungan, prosesKunjungan, kunjunganBaru, cekDataKunjungan, loading, loading_screen }
}

</script>