<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': menuActive}">
        <a href="#" class="nav-link" @click="menuActive = !menuActive">
          <i class="nav-icon fas fa-archive"></i>
          <p>
            Console Box
            <!-- <i class="right fas fa-angle-left"></i> -->
          </p>
        </a>
        <ul class="nav nav-treeview">
          <li class="nav-item pl-2" v-if="props.akses.includes('anjungan') || props.akses.includes('semua')">
            <a href="#" class="nav-link" @click="konfirmasiKeAnjungan">
              <i class="far fa-plus-square nav-icon"></i>
              <p class="text-sm">Anjungan Pasien Baru</p>
            </a>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('anjungan_bpjs') || props.akses.includes('semua')">
            <a href="#" class="nav-link" @click="konfirmasiKeAnjunganBpjs">
              <i class="far fa-plus-square nav-icon"></i>
              <p class="text-sm">Anjungan Pasien Lama (BPJS)</p>
            </a>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('anjungan_nonbpjs') || props.akses.includes('semua')">
            <a href="#" class="nav-link" @click="konfirmasiKeAnjunganNonBpjs">
              <i class="far fa-plus-square nav-icon"></i>
              <p class="text-sm">Anjungan Pasien Lama (NonBPJS)</p>
            </a>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('display') || props.akses.includes('semua')">
            <a href="#" class="nav-link" @click="konfirmasiKeDisplay">
              <i class="far fa-eye nav-icon"></i>
              <p class="text-sm">Display</p>
            </a>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('display_farmasi') || props.akses.includes('semua')">
            <a href="#" class="nav-link" @click="konfirmasiKeDisplayFarmasi">
              <i class="far fa-eye nav-icon"></i>
              <p class="text-sm">Display Farmasi</p>
            </a>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('display_dokter') || props.akses.includes('semua')">
            <a href="#" class="nav-link" @click="konfirmasiKeDisplayDokter">
              <i class="far fa-eye nav-icon"></i>
              <p class="text-sm">Display Dokter</p>
            </a>
          </li>
        </ul>
      </li>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import Swal from 'sweetalert2'
import router from '@/router'

const menuActive = ref(false)

const props = defineProps({
  akses: Array
})

const konfirmasiKeAnjungan = () => {
  Swal.fire({
		text: 'Anda ingin ke Anjungan Pasien Baru?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: `Ya`,
		cancelButtonText: `Batal`,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
      localStorage.setItem('console', '00d2088472bdf05ad135b408a861114f')
      router.push('/')
      setTimeout(function(){
        location.reload();
      }, 1000)
		} 
	})
}

const konfirmasiKeAnjunganBpjs = () => {
  Swal.fire({
		text: 'Anda ingin ke Anjungan BPJS?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: `Ya`,
		cancelButtonText: `Batal`,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
      localStorage.setItem('console', '996ab9c0cbf34581cc9b188397fc54c1')
      router.push('/')
      setTimeout(function(){
        location.reload();
      }, 1000)
		} 
	})
}

const konfirmasiKeAnjunganNonBpjs = () => {
  Swal.fire({
		text: 'Anda ingin ke Anjungan BPJS?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: `Ya`,
		cancelButtonText: `Batal`,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
      localStorage.setItem('console', '8c151762f0320b2fc380b5583cc2e64a')
      router.push('/')
      setTimeout(function(){
        location.reload();
      }, 1000)
		} 
	})
}

const konfirmasiKeDisplay = () => {
  Swal.fire({
		text: 'Anda ingin ke Display?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: `Ya`,
		cancelButtonText: `Batal`,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
      localStorage.setItem('console', '07306d7744f2656c904d5bcea291aa4e')
      router.push('/')
      setTimeout(function(){
        location.reload();
      }, 1000)
		} 
	})
}
const konfirmasiKeDisplayFarmasi = () => {
  Swal.fire({
		text: 'Anda ingin ke Display Farmasi?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: `Ya`,
		cancelButtonText: `Batal`,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
      localStorage.setItem('console', '94cb6d020f7b0d4e830286ef33797799')
      router.push('/')
      setTimeout(function(){
        location.reload();
      }, 1000)
		} 
	})
}
const konfirmasiKeDisplayDokter = () => {
  Swal.fire({
		text: 'Anda ingin ke Display Dokter?',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: `Ya`,
		cancelButtonText: `Batal`,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
      localStorage.setItem('console', '59993112d26ac0cdc46949857989601f')
      router.push('/')
      setTimeout(function(){
        location.reload();
      }, 1000)
		} 
	})
}

</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>