<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': rekamMedisActive}">
        <a href="#" class="nav-link" @click="rekamMedisActive = !rekamMedisActive">
          <i class="nav-icon fas fa-book-medical"></i>
          <p>
            Rekam Medis
            <!-- <i class="right fas fa-angle-left"></i> -->
          </p>
        </a>
        <ul class="nav nav-treeview">
          <li class="nav-item pl-2" v-if="props.akses.includes('data_pasien') || props.akses.includes('semua')">
            <router-link to="/data-pasien" class="nav-link">
              <i class="fas fa-file nav-icon"></i>
              <p class="text-sm">Data Pasien</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('rekam_medis_pasien') || props.akses.includes('semua')">
            <router-link to="/rekam-medis" class="nav-link">
              <i class="fas fa-stethoscope nav-icon"></i>
              <p class="text-sm">Rekam Medis Pasien</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('data_formulir') || props.akses.includes('semua')">
            <router-link to="/data-formulir" class="nav-link">
              <i class="fas fa-file nav-icon"></i>
              <p class="text-sm">Data Formulir</p>
            </router-link>
          </li>
          <li class="nav-item pl-2" v-if="props.akses.includes('mapping_wilayah') || props.akses.includes('semua')">
            <router-link to="/mapping-wilayah" class="nav-link">
              <i class="fas fa-map nav-icon"></i>
              <p class="text-sm">Mapping Wilayah</p>
            </router-link>
          </li>
        </ul>
      </li>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const rekamMedisActive = ref(false)

const props = defineProps({
  akses: Array
})

</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>