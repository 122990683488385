<template>
  <div class="hold-transition login-page fade-in" style="background: rgb(41,128,185); background: linear-gradient(148deg, rgba(41,128,185,1) 0%, rgb(255, 255, 255) 25%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 75%, rgba(255,106,136,1) 100%);">
    <div class="login-box">
      <div class="login-logo"></div>
      <div class="item-logos">
        <img src="/assets/solumed.gif" style="display:block; margin:auto" alt="logo" width="250" border="5"><br>
      </div>
      <div class="card">
        <div class="card-body login-card-body">
          <div v-if="resetToken">
            <h4 class="text-center text-bold mb-4">Reset Your Password</h4>
            <form @submit.prevent="handleResetPassword">
              <div class="input-group mb-3">
                <input :type="passwordFieldType" v-model="newPassword" class="form-control" placeholder="New password" required>
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i :class="{'fas fa-eye-slash': passwordFieldType === 'password', 'fas fa-eye': passwordFieldType === 'text'}" @click="togglePasswordVisibility"></i>
                  </span>
                </div>
              </div>
              <div class="input-group mb-3">
                <input :type="passwordFieldType" v-model="confirmPassword" class="form-control" placeholder="Confirm password" required>
              </div>
              <button class="btn btn-primary btn-block">Reset Password</button>
            </form>
          </div>
          <div v-else>
            <h4 class="text-center text-bold">Sistem Informasi Managemen <br> <h6 class="text-center text-bold mt-1"> Klinik, Apotek, Praktek Dokter  & Nakes Lainnya</h6></h4>
           
       
            <h5 class="login-box-msg  mt-3 text-sm">Silakan Login</h5>
            <form @submit.prevent="handleLogin">
              <div class="input-group mb-3">
                <input type="email" v-model="credential.email" class="form-control" placeholder="Email" />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input type="password" v-model="credential.password" class="form-control" placeholder="Password" />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input :value="captcha.question" class="form-control" disabled />
                <input type="text" v-model="captchaAnswer" class="form-control" placeholder="Answer" />
              </div>
              <div class="row">
                <div class="col mt-3">
                  <button class="btn btn-primary btn-block text-center" id="button">
                    <div>Sign In</div>
                    <div v-if="loading" class="spinner"></div>
                  </button>
                </div>
              </div>
              <p class="mt-3 text-center">
                <a href="#" @click="toggleForgotPassword">Lupa Password?</a>
              </p>
            </form>
          </div>
          <div v-if="showForgotPassword">
            <select v-model="selectedBranch" class="form-control mt-2">
              <option disabled value="">Pilih Cabang</option>
              <option v-for="branch in branches" :key="branch.id" :value="branch.id">
                {{ branch.name }}
              </option>
            </select>
            <input type="email" v-model="forgotEmail" class="form-control mt-2" placeholder="Your Email Pemulihan" />
            <button class="btn btn-warning btn-block text-center mt-2" @click="submitForgotPassword">Kirim Link Reset</button>
          </div>
        </div>
      </div>
    </div>
    <br>
    <small class="text-sm text-center text-bold"> © 2021 Made With ❤️ Solumedis Sofware Solutions. All rights reserved.
    </small>
  </div>
</template>


<script>
import router from '@/router';
import { ref, reactive,onMounted } from 'vue';
import Swal from 'sweetalert2';
import store from '@/store';
import axios from 'axios';
export default {
  setup() {
    const loading = ref(false);
    const resetToken = ref(null);
    const newPassword = ref('');
    const confirmPassword = ref('');
    const showForgotPassword = ref(false);
    const passwordFieldType = ref('password');
    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
    };
    const forgotEmail = ref('');
    const credential = reactive({
      email: '',
      password: ''
    });
    const captcha = ref(generateCaptcha());
    const captchaAnswer = ref('');
    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      resetToken.value = urlParams.get('token');
    });
    const handleResetPassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        Swal.fire('Passwords do not match', '', 'error');
        return;
      }
      try {
        const response = await axios.post('user/reset-password', {
          token: resetToken.value,
          newPassword: newPassword.value
        });
        console.log('Server Response:', response);
        Swal.fire('Password reset successfully', '', 'success').then(() => {
          resetToken.value = null;
          newPassword.value = '';
          confirmPassword.value = '';
          router.push('/login');
        });
      } catch (error) {
        Swal.fire('Failed to reset password', '', 'error');
      }
    };

    function toggleForgotPassword() {
      showForgotPassword.value = !showForgotPassword.value;
    }
    const branches = ref([
      // { id: 1, name: 'Cabang Jakarta' },
      { id: 2, name: 'Klinik Pratama Ratnasari Sehat' },
      { id: 3, name: 'Klinik Ratna Sari Sehat Ciwidey' },
      { id: 4, name: 'Klinik Seroja Medika Cangkuang' },
      // { id: 5, name: 'Klinik Ratna Sari Sehat Ciwidey' },
      { id: 6, name: 'Klinik Ratnasari Sehat Pasirjambu' },
      { id: 9, name: 'Klinik DPP M Yossa Effendi' },
      { id: 10, name: 'Klinik Sehat Medika Utama' },
      { id: 11, name: 'Klinik Citere Medikal Center' },
    ]);
    const selectedBranch = ref(null);

    async function submitForgotPassword() {
  if (!selectedBranch.value) {
    Swal.fire({
      text: 'Please select a branch',
      icon: 'warning',
      showConfirmButton: true
    });
    return;
  }

  try {
    const response = await axios.post(`/user/forgot-password/${selectedBranch.value}`, {
      email_pemulihan: forgotEmail.value
    });
    Swal.fire({
      text: response.data.message,
      icon: 'success',
      timer: 3000
    });
    showForgotPassword.value = false; // Hide form after submission
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      text: 'Failed to send reset link',
      icon: 'error',
      showConfirmButton: true
    });
  }
}

    function generateCaptcha() {
      const num1 = Math.floor(Math.random() * 10);
      const num2 = Math.floor(Math.random() * 10);
      return {
        question: `${num1} + ${num2}`,
        answer: num1 + num2
      };
    }

    const handleLogin = async () => {
      if (parseInt(captchaAnswer.value) !== captcha.value.answer) {
        Swal.fire({
          text: 'Incorrect captcha answer',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500
        });
        captcha.value = generateCaptcha(); // Reset captcha
        captchaAnswer.value = ''; // Clear answer field
        return;
      }

      loading.value = true;
      try {
        const response = await store.dispatch('auth/login', credential);
        console.log(response);
        if (response === 'wrong_credential') {
          Swal.fire({
            text: 'Email atau Password Salah',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          router.push('/absensi-karyawan');
          // location.reload();
        }
      } catch (error) {
        console.error('Login Error:', error);
        Swal.fire({
          text: 'Login failed due to an unexpected error. Please try again.',
          icon: 'error',
          showConfirmButton: true
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      handleLogin,
      submitForgotPassword,
      toggleForgotPassword,
      showForgotPassword,
      forgotEmail,
      credential,
      handleResetPassword,
      newPassword,
      confirmPassword,
      resetToken,
      loading,
      captcha,
      captchaAnswer,
      branches,
      selectedBranch,
      passwordFieldType,
      togglePasswordVisibility
    };
  }
};
</script>


<style>
  #button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  svg {
    width: 2px;
    height: 20px;
    margin-left: 5px;
  }
  
  .fade-in {
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes fadeInAnimation {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  #button:hover {
  background: linear-gradient(145deg, #e06363, #5365ed);
  box-shadow: 0 0 20px rgba(40, 233, 251, 0.476);
}
  @media (max-width: 768px) {
    .login-box, .login-card-body {
      width: 90%;
      margin: auto;
    }
  
    .item-logos img {
      width: 80%;
    }
  }

  .card-transition {
    transition: transform 0.3s ease-in-out;
  }
  
  .card-hover:hover {
    transform: scale(1.03);
  }
</style>