<template>
    <div class="hold-transition card body"
        style="background: rgb(41,128,185);
background: linear-gradient(148deg, rgba(41,128,185,1) 0%, rgb(255, 255, 255) 25%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 75%, rgba(255,106,136,1) 100%);">
        <LoadingScreen v-if="loading_screen" />


        <div class="content-header">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header "
                        style="background: rgb(244, 244, 244); background: linear-gradient(148deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 25%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 75%, rgb(224, 121, 121) 100%);">
                        <div class="row">
                            <div class="col-3">
                                <div class="item-logos">
                                    <img src="/assets/solumed.gif" style="display:block; margin:auto" alt="logo"
                                        width="150">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="text-center mt-4">
                                    <!-- <h1>Selamat Datang Di</h1> -->
                                    <h1 class="text-bold mt-4">Faskes Tingkat 1 {{ cabang.nama }} </h1>
                                    <span class="text-bold">{{ cabang.alamat }} telp. {{ cabang.telepon }} </span><br>
                                    <span class="text-bold">{{ cabang.sio }} </span>
                                    <h1><b>{{ currentTime }}</b></h1>

                                </div>
                            </div>
                            <div class="col-3">
                                <div class="item-logos">
                                    <img :src="image ? image : defaultImage"
                                        alt="" width="150" style="display: block; margin: auto;">
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="card-body"
                        style="background: rgb(41,128,185); background: linear-gradient(148deg, rgba(41,128,185,1) 0%, rgb(255, 255, 255) 25%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 75%, rgba(255,106,136,1) 100%);">
                        <div class="row align-items-center">
                            <div class="col-7">
                                <table class="table table-bordered table-hover">
                                    <thead scope="col" class="text-center text-white"
                                        style="background-color: rgb(10, 6, 241);"></thead>
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-lg text-center text-white"
                                                style="background-color: rgb(10, 6, 241);">ANTREAN DIPANGGIL PASIEN BPJS
                                            </th>

                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr v-for="row in get_bpjs" :key="row.id"
                                            style="background-color:rgba(70, 123, 248, 0.822);">
                                            <td class="text-lg text-center text-white" scope="row">
                                                <div>
                                                    <h1>{{ row.nomor }}</h1>
                                                    <h5>POLI - {{ row.tb_poli.nama }}</h5>
                                                    <b>{{ row.tb_pasien.nama }}</b> - {{ row.tb_pasien.nomor_rekam_medis }}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-lg text-center text-white"
                                                style="background-color: rgb(196, 3, 3);">ANTREAN DIPANGGIL PASIEN MANDIRI
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in get_non_bpjs" :key="row.id"
                                            style="background-color: rgb(248, 70, 70);">
                                            <td class="text-lg text-center text-white" scope="row">
                                                <div>
                                                    <h1>{{ row.nomor }}</h1>
                                                    <h5>POLI - {{ row.tb_poli.nama }}</h5>
                                                    <b>{{ row.tb_pasien.nama }}</b> - {{ row.tb_pasien.nomor_rekam_medis }}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <div class="col-4">
                                <div class="ml-4  text-center">
                                    <iframe width="530" height="300"
                                        src="https://www.youtube.com/embed/videoseries?si=n3zy7u-clsM50Mon&amp;list=PL2l8FKxks2xk15QQ6loBYq4i_sElLD6qq"
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay=true; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <br>
                <br>
                <br>

            </div>
        </div>
    </div>
</template>

<script setup>

import LoadingScreen from '@/components/LoadingScreen'


import { computed, onMounted, ref } from 'vue'
import axios from 'axios'
import store from '@/store'


const user = computed(() => store.getters['auth/user'])
const cabang = computed(() => store.getters['auth/cabangs'])
const logo = computed(() => store.getters['auth/logo'])
const image = ref()
image.value = logo.value.config.baseURL + '/' + logo.value.data.data.logo





const anjungans = ref([])
const get_bpjs = ref([])
const get_non_bpjs = ref([])
const currentTime = ref('')


const anjungan = async () => {
    let response = await axios.get(`anjungan/get/${user.value.data.tbCabangId}`)
    anjungans.value = response.data.data
}
const anjungan_bpjs = async () => {
    let response = await axios.get(`anjungan/get_layanan/${user.value.data.tbCabangId}/2?page=1`)
    get_bpjs.value = response.data.data
}
const anjungan_nonbpjs = async () => {
    let response = await axios.get(`anjungan/get_layanan/${user.value.data.tbCabangId}/1?page=1`)
    get_non_bpjs.value = response.data.data
}

// setInterval(function () {
//     anjungan_bpjs()
//     anjungan_nonbpjs()
//     updateTime()
// }, 5000);

//waktu
const updateTime = async () => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    currentTime.value = `${hours}:${minutes}:${seconds}`
}

onMounted(() => {
    updateTime()

    anjungan()
    anjungan_bpjs()
    anjungan_nonbpjs()
})


</script>