<template>
	<li class="nav-item" v-bind:class="{'menu-is-opening menu-open': masterActive}">
	    <a href="#" class="nav-link" @click="masterActive = !masterActive">
	      <i class="nav-icon fas fa-database"></i>
	      <p>
	        Master Data/Administrasi
	        <!-- <i class="right fas fa-angle-left"></i> -->
	      </p>
	    </a>
	    <ul class="nav nav-treeview">
	      <li class="nav-item pl-2" v-if="props.akses.includes('user_hak_akses') || props.akses.includes('semua')">
	        <router-link to="user-dan-hak-akses" class="nav-link">
	          <i class="fas fa-users-cog nav-icon"></i>
	          <p class="text-sm">User dan Hak Akses</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('setting_klinik') || props.akses.includes('semua')">
	        <router-link to="/setting-klinik" class="nav-link">
	          <i class="fas fa-cog nav-icon"></i>
	          <p class="text-sm">Setting Klinik</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('setup_cabang') || props.akses.includes('semua')">
	        <router-link to="/setup-cabang" class="nav-link">
	          <i class="fas fa-cog nav-icon"></i>
	          <p class="text-sm">Setup Cabang</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('data_tindakan') || props.akses.includes('semua')">
	        <router-link to="/tindakan" class="nav-link">
	          <i class="fas fa-stethoscope nav-icon"></i>
	          <p class="text-sm">Data Tindakan</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('administrasi_pemeriksaan') || props.akses.includes('semua')">
	        <router-link to="/administrasi" class="nav-link">
	          <i class="fas fa-file nav-icon"></i>
	          <p class="text-sm">Administrasi &amp; Pemeriksaan</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('poli') || props.akses.includes('semua')">
	        <router-link to="/poli" class="nav-link">
	          <i class="fas fa-file nav-icon"></i>
	          <p class="text-sm">Poli</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('layanan') || props.akses.includes('semua')">
	        <router-link to="/layanan" class="nav-link">
	          <i class="fas fa-file nav-icon"></i>
	          <p class="text-sm">Layanan</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('jadwal_shift') || props.akses.includes('semua')">
	        <router-link to="/jadwal-shift" class="nav-link">
	          <i class="fas fa-file nav-icon"></i>
	          <p class="text-sm">Jadwal Shift</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('pergantian_shift') || props.akses.includes('semua')">
	        <router-link to="/pergantian-shift" class="nav-link">
	          <i class="fas fa-file nav-icon"></i>
	          <p class="text-sm">Pergantian Shift</p>
	        </router-link>
	      </li>
	      <li class="nav-item pl-2" v-if="props.akses.includes('laporan_pergantian_shift') || props.akses.includes('semua')">
	        <router-link to="/laporan-pergantian-shift" class="nav-link">
	          <i class="fas fa-file nav-icon"></i>
	          <p class="text-sm">Laporan Pergantian Shift</p>
	        </router-link>
	      </li>
		  <li class="nav-item pl-2" v-if="props.akses.includes('ruang_periksa') || props.akses.includes('semua')">
            <router-link to="/ruang-periksa" class="nav-link">
	            <i class="far fa-circle nav-icon"></i>
              <p class="text-sm">Ruang Periksa</p>
            </router-link>
          </li>
		  <li class="nav-item pl-2" v-if="props.akses.includes('setting_user') || props.akses.includes('semua')">
            <router-link to="/setting-user" class="nav-link">
			  <i class="fas fa-cog nav-icon"></i>
              <p class="text-sm">Setting User</p>
            </router-link>
          </li>
	    </ul>
	  </li>
</template>


<script setup>
import { ref, defineProps } from 'vue'

const masterActive = ref(false)

const props = defineProps({
  akses: Array
})
  
</script>

<style>
  .menu-is-open{
    background-color: #494E53;
    transition: all 3s;
  }

  .show{
    display: block;
  }

</style>